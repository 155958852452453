//HTML and Body Styling
html {
    font-size: 112.5%;

    font-variant-numeric: lining-nums;

    @include breakpoint(S) {
        font-size: 100%;
    }
    @include breakpoint(XXXL) {
        font-size: 125%;
    }
}

body {
    line-height: 1.5rem;
    font-weight: 400;
    font-family: $font-stack-primary;

    &.menu-open {
        overflow: hidden;
    }
}

// Load desired fonts

@font-face {
    font-family: 'Tiempos Headline';
    src: url('/wp-content/themes/uphotelagency/webfonts/tiempos-headline-web-regular.woff2') format('woff2');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Tiempos Headline';
    src: url('/wp-content/themes/uphotelagency/webfonts/TiemposHeadlineWeb-Bold.woff2') format('woff2');
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Graphik';
    src: url('/wp-content/themes/uphotelagency/webfonts/Graphik-Regular-Web.woff2') format('woff2');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Graphik';
    src: url('/wp-content/themes/uphotelagency/webfonts/Graphik-Bold-Web.woff2') format('woff2');
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}


//System fallback fonts for Tiempos Headline

@font-face {
    font-family: 'Charter';
    src: local('Charter');
    ascent-override: 82%;
}

@font-face {
    font-family: 'Bitstream Charter';
    src: local('Bitstream Charter');
    size-adjust: 99.125%;
    ascent-override: 82%;
}

@font-face {
    font-family: 'Sitka Text';
    src: local('Sitka Text');
    size-adjust: 92%;
    ascent-override: 86%;
}

@font-face {
    font-family: 'Cambria';
    src: local('Cambria');
    size-adjust: 102%;
    ascent-override: 78%;
}


//System fallback fonts for Graphik

@font-face {
    font-family: 'Inter';
    src: local('Inter');
    size-adjust: 99.5%; //Size relative to main font
    ascent-override: 90%; //Adjust the vertical position relative to main font
}

@font-face {
    font-family: 'Roboto';
    src: local('Roboto');
    size-adjust: 105.5%; //Size relative to main font
    ascent-override: 85%; //Adjust the vertical position relative to main font
}

@font-face {
    font-family: 'Helvetica Neue';
    src: local('Helvetica Neue');
    size-adjust: 105.5%; //Size relative to main font
    ascent-override: 85%; //Adjust the vertical position relative to main font
}

@font-face {
    font-family: 'Arial Nova';
    src: local('Arial Nova');
    size-adjust: 106.5%; //Size relative to main font
    ascent-override: 81%; //Adjust the vertical position relative to main font
}

@font-face {
    font-family: 'Nimbus Sans';
    src: local('Nimbus Sans');
    size-adjust: 106.25%; //Size relative to main font
    ascent-override: 86%; //Adjust the vertical position relative to main font
}

@font-face {
    font-family: 'Arial';
    src: local('Arial');
    size-adjust: 106.2%; //Size relative to main font
    ascent-override: 82%; //Adjust the vertical position relative to main font
}


//Import base style partials
@import "type-scales/type-scales";
@import "headings";
@import "paragraphs";
@import "content-rhythm";
@import "lists";
@import "links";
@import "tables";
@import "blockquotes";
@import "captions";
@import "hr";
