@import "../gutenberg_block";

.stats-grid {
    margin-left: -1rem;
    margin-right: -1rem;
    gap: 2rem;
    
    @include breakpoint(MS) {
        gap: 1rem;
    }

    @include breakpoint(S) {
        gap: 2rem;
        margin-left: -0.875rem;
        margin-right: -0.875rem;
    }
}