@import "../gutenberg_block";

.numbers-blocks-section{
    .numbers-stats{
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        .numbers{
            width: 33.333%;
            display: flex;
            flex-direction: column;
            text-align: center;
            .stat{
                font-family: $font-stack-headings;
                font-weight: 400;
                font-size: 4.5rem;
                line-height:normal;
            }
            .title{
                font-family: $font-stack-primary;
                @include size-M;
            }
        }
        @include breakpoint(S) {
            gap: 3.429rem 0;
            .numbers{
                width: 100%;
                padding-right: 0;
            }
        }
    }
}