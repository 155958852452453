@import "../gutenberg_block";
.single-number-container.container{
    @include breakpoint(MS){
        padding-left: 3rem;
        padding-right: 3rem;
    }
}
.number-callout {
    border-radius: 0.5rem;
    overflow: hidden;
    @include color(color, 'body');

    p {
        letter-spacing: 0.0625em;
        font-weight: bold;
        text-transform: uppercase;
        @include size-XS;
        text-align: center;
        padding: 0 1rem;
        width: 100%;
    }
    .number-count {
        font-family: $font-stack-headings;
        @include size-3XL;
        font-weight: bold;

        @include breakpoint(S) {
            // this is so it clears the max font-size set inline
            font-size: 1.75rem !important;
            line-height: 2.25rem !important;
        }
    }
    &.small {
        width: calc(25% - 2rem);
        padding-bottom: calc(25% - 2rem);
        height: 0;
        position: relative;
        margin: 1rem 0;
        @include breakpoint(MS) {
            width: calc(23%);
        }

        @include breakpoint(S) {
            margin: unset;
            width: calc(50% - 1.75rem);
            padding-bottom: calc(50% - 1.75rem);
        }
        .number-count {
            margin: 0;
        }
        .number-callout-inner {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }
        svg {
            width: 4rem;
            height: 4rem;
            margin-bottom: 1rem;

            @include breakpoint(S) {
                margin-bottom: 0.25rem;
            }
        }
    }
    &.large {
        width: 75%;
        padding-bottom: 75%;
        height: 0;
        position: relative;

        @include breakpoint(XS) {
            width: 100%;
            padding-bottom: 100%;
        }
        .number-callout-large-inner {
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            width: 100%;
        }
        svg {
            width: 7rem;
            height: 7rem;
            margin-bottom: 1.25rem;

            @include breakpoint(S) {
                margin-bottom: 1rem;
            }
        }
        .number-count {
            margin-bottom: 1rem;

            @include breakpoint(S) {
                margin-bottom: 0;
            }
        }
    }
    &.theme--default {
        @include color(color, 'accent-primary');
    }
    &.shadow {
        transition: all 0.4s ease !important;

        &:hover {
            transform: translateY(-0.25rem) !important;
            box-shadow: 0px 4px 9px rgba($color-body, 0.25);
        }
    }
}