@import "../gutenberg_block";

.work-listing-header {
    @include breakpoint(M) {
        flex-direction: column;
    }
    @include breakpoint(XS) {
        text-align: center;
    }
    h2 {
        margin-right: 2rem;
        @include color(color, 'body');
        @extend %transition;
        max-width: 40rem;

        @include breakpoint(XS) {
            margin-right: 0;
        }
    }
    .button {
        transform: translateY(-2rem);

        @include breakpoint(XS) {
            transform: translateY(0);
        }
    }
    hr {
        @extend %transition;
        @include color(border-color, 'body');

        @include breakpoint(XS) {
            margin-left: auto;
            margin-right: auto;
        }
    }
}

.spacing.work-listing-wrapper:first-child {
    padding-top: 9rem;

    @include breakpoint(XS) {
        padding-top: 6rem;
    }
}

.work-listing {
    margin-left: -1rem;
    margin-right: -1rem;

    @include breakpoint(XS) {
        margin-left: 0;
        margin-right: 0;
    }
}

.work-item {
    position: relative;
    margin: 2rem 1rem;
    width: calc(50% - 2rem);
    height: 0;
    padding-bottom: calc(50% - 2rem);

    @media all and (max-width: 1000px) {
        min-height: 24rem;
    }
    @include breakpoint(XS) {
        width: calc(100vw - 2.5rem);
        padding-bottom: calc(100vw - 2.5rem);
        margin: 1.5rem 0;
    }
    .work-item-content {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        overflow: hidden;
        border-radius: 4px;
        @extend %nav-transition;

        @include breakpoint(XS) {
            &:after {
                content: "";
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                z-index: 2;
                background: linear-gradient(180deg, rgba(#000, 0) 72.66%, rgba(#000, 0.5) 100%);
            }
        }
    }
    .work-item-inner {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 5;
        opacity: 0;
        padding: 3.5rem 3.5rem 5rem 3.5rem;
        text-align: center;
        border-radius: 4px;
        @extend %nav-transition;
        @include color(background-color, 'background');

        &:after {
            content: "";
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            border: 2px solid;
            @include color(border-color, 'body');
            border-radius: 4px;
            z-index: 5;
        }
        @include breakpoint(M) {
            padding: 1.5rem 1.5rem 4rem 1.5rem;
        }
        @include breakpoint(XS) {
            padding: 1.25rem 1rem 3.5rem 1rem;

            hr {
                margin-top: 0;
            }
        }
        .work-logo {
            @include breakpoint(M) {
                margin-bottom: 1rem;
            }
            @include breakpoint(XS) {
                margin-bottom: 0.5rem;
            }
            svg {
                max-width: 8.5rem;
                max-height: 6rem;
                width: auto;
                height: auto;

                @include breakpoint(M) {
                    max-width: 5rem;
                    // max-height: 4.5rem;
                }
                @include breakpoint(XS) {
                    max-width: 5rem;
                    max-height: 3.75rem;
                }
            }
        }
        .work-logo,
        h4,
        hr,
        p {
            opacity: 0;
            @extend %transition;
            transform: translateY(-0.5rem);
            max-width: 100%;
        }
    }
    hr {
        @include color(border-color, 'body');
    }
    h4 {
        @include color(color, 'body');
    }
    img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
    .mob-explore {
        display: none;
        position: absolute;
        bottom: 1.625rem;
        z-index: 3;
        color: #fff;

        &:after {
            content: "";
            position: absolute;
            width: 1.5625rem;
            height: 1px;
            background: #fff;
            top: calc(50% - 1px);
        }
    }
    &:nth-child(odd) {
        @include breakpoint(S) {
            align-self: flex-end;
        }
        .work-title {
            @extend %transition;
            position: absolute;
            left: -1rem;
            bottom: 0;
            transform: rotate(-90deg);
            transform-origin: bottom left;

            @include breakpoint(S) {
                @include size-S;
            }
            @include breakpoint(XS) {
                text-align: center;
                @include size-XL;
                width: 100%;
                left: -0.375rem;
            }
        }
        .work-cats {
            @extend %transition;
            position: absolute;
            right: calc(100% + 2.5rem);
            top: 0;
            transform: rotate(-90deg);
            transform-origin: top right;
            width: 100%;
            text-align: right;

            @include breakpoint(S) {
                @include size-XS;
                right: calc(100% + 2.25rem);
            }
        }
        .mob-explore {
            right: 0;
            padding-right: 2.5rem;

            svg {
                margin-right: 0.5rem;
                display: block;
            }
            &:after {
                right: 0;
            }
            @include breakpoint(XS) {
                display: flex;
            }
        }
    }
    &:nth-child(even) {
        .work-title {
            position: absolute;
            left: calc(100% + 2.25rem);
            top: 0;
            transform: rotate(90deg);
            transform-origin: top left;
            width: 100%;

            @include breakpoint(S) {
                @include size-S;
            }
            @include breakpoint(XS) {
                text-align: center;
                @include size-XL;
            }
        }
        .work-cats {
            position: absolute;
            right: -1rem;
            bottom: 0;
            transform: rotate(90deg);
            transform-origin: bottom right;
            width: 100%;
            text-align: right;

            @include breakpoint(S) {
                @include size-XS;
                right: -1.25rem;
            }
        }
        .mob-explore {
            left: 0;
            padding-left: 2.5rem;

            svg {
                margin-left: 0.5rem;
                display: block;
                order: 2;
            }
            &:after {
                left: 0;
            }
            @include breakpoint(XS) {
                display: flex;
            }
        }
    }
    .button {
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
        background: none;
        border: 0;

        @include breakpoint(XS) {
            padding-bottom: 0.5rem;
        }
        &:before {
            display: none;
        }
        &:after {
            content: "" !important;
            display: block !important;
            @include color(background-color, 'accent-primary');
            position: absolute;
            border-radius: 100%;
            z-index: -1;
            width: 10rem;
            height: 10rem;
            top: -0.75rem;
            left: calc(50% - 5rem);

            @include breakpoint(XS) {
                top: -0.5rem;
            }
        }
    }
    &:hover,
    &.hover {
        .work-item-inner {
            opacity: 1;
            
            .work-logo,
            h4,
            hr,
            p {
                opacity: 1;
                transform: translateY(0);
            }
            .work-logo {
                transition-delay: 0.5s;
            }
            .font-headings {
                transition-delay: 0.7s;
            }
            hr {
                transition-delay: 0.9s;
            }
            p {
                transition-delay: 1.1s;
            }
        }
        @include breakpoint(XS) {
            .work-title {
                opacity: 0;
            }
            &:nth-child(odd) {
                .work-item-content {
                    transform: translateX(-1.25rem);
                }
            }
            &:nth-child(even) {
                .work-item-content {
                    transform: translateX(1.25rem);
                }
            }
        }
    }
}