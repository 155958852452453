@import "../gutenberg_block";

.latest-articles-wrap {
    .articles-imgs {
        width: 55%;
        aspect-ratio: 16 / 9;
        min-height: Min(16rem, 100%);

        @include breakpoint(XS) {
            width: 100%;
        }
    }
    .latest-articles {
        display: flex;
        width: 45%;

        @include breakpoint(XS) {
            width: 100%;
        }
    }
    .articles-imgs {
        position: relative;
    }
}

.article-img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0;
    @extend %transition;

    &.active {
        opacity: 1;
    }
    p {
        position: relative;
        z-index: 2;
    }
    img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border-radius: 0.25rem;
    }
}

.latest-articles {
    padding-left: 2rem;

    @include breakpoint(XS) {
        padding-left: 0;

        > h3 {
            margin-top: 0;
        }
    }
}

.articles-list {
    display: flex;
    flex-direction: column;
    gap: 3rem;
    justify-content: center;

    @include breakpoint(XS) {
        gap: 1.5rem;
        margin-bottom: 0.75rem;
    }
    a {
        padding-bottom: 1.5rem;
        border-bottom: 1px solid;
        @include color(border-color, 'body-10');

        &:first-child {
            border-top: 1px solid;
            @include color(border-color, 'body-10');

            @include breakpoint(XS) {
                border-top: 0;
            }
        }
        @include breakpoint(XS) {
            &:last-child {
                border: 0;
            }
        }
        .article-name {
            flex-grow: 1;
            padding-right: 1.5rem;

            h4 {
                @extend %transition;
            }
        }
        &:hover {
            .button {
                transform: translateX(0.25rem);
            }
            h4 {
                @include color(color, 'accent-magenta');
            }
        }
    }
    .mob-article-img {
        display: none;

        img {
            border-radius: 0.25rem;
            aspect-ratio: 16 / 9;
            height: auto;
            min-height: 14rem;
            width: 100%;
            display: block;
            margin-bottom: 0.5rem;
        }
        @include breakpoint(XS) {
            display: block;
        }
    }
}

.latest-articles-header {
    .left {
        padding-right: 3rem;

        @include breakpoint(XS) {
            padding-right: 0;
        }
    }
    .button {
        flex-shrink: 0;

        @include breakpoint(XS) {
            display: none;
        }
    }
}

.news-insights-block {
    .mob-articles-link {
        display: none;

        @include breakpoint(XS) {
            display: inline-flex;
        }
    }
}