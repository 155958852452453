@import "../gutenberg_block";

.logo-grid-item {
    width: 25%;
    padding: 1.5rem;
    display: flex !important;

    @include breakpoint(S) {
        width: 50%;
    }
    @include breakpoint(XS) {
        width: auto;
        height: 7rem;
    }
    img,
    svg {
        max-width: 10rem;
        max-height: 6rem;
    }
}