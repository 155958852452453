@import "../gutenberg_block";

.cta-directory {
    .cta-directory-content {
        width: 20rem;
        margin-right: 7rem;
        flex-shrink: 0;
        flex-grow: 1;

        @include breakpoint(M) {
            margin-right: 2.5rem;
            width: 40%;
        }
        @include breakpoint(XS) {
            width: 100%;
            margin: 0;
            text-align: center;
            margin-bottom: 3rem;
        }
        svg {
            width: 14rem;
            max-height: 20rem;
            max-width: 90%;
            display: block;
            @include color(color, 'accent-primary');
            margin-bottom: 3.5rem;

            @include breakpoint(XS) {
                margin: 0 auto 2rem auto;
                width: 6.5rem;
            }
        }
    }
}

.directory {
    border-radius: 0.25rem;
    padding: 1.888rem 1.333rem;
    border: 2px solid;
    @include color(border-color, 'body-10');
    margin-bottom: 2rem;

    @include breakpoint(XS) {
        padding: 0.875rem 1.125rem;
        margin-bottom: 1.5rem;

        h3 {
            @include size-L;
        }
        p {
            @include size-S;
        }
    }
    h3,
    p {
        @extend %transition;
    }
    .text {
        margin-right: 1.5rem;
    }
    &:hover {
        box-shadow: 0px 4px 9px rgba($color-body, 0.1);
        transform: translateY(-0.5rem);

        h3,
        p {
            @include color(color, 'accent-magenta');
        }
        .button {
            @include color(border-color, 'accent-magenta');

            svg {
                @include color(color, 'accent-magenta');
            }
        }
    }
}