@import "../gutenberg_block";

.gallery-carousel-wrapper {
    position: relative;
    overflow: hidden;
}

.gallery-carousel {
    .gallery-carousel-slide-img {
        @extend %transition;
        position: relative;
        height: 30rem;
        max-height: calc(90vh - 5rem);
        margin: 2.5rem 1rem;
        width: calc(100vw - 16rem);
        border-radius: 4px;
        overflow: hidden;
        box-shadow: 0px 4px 9px rgba($color-body, 0.1);

        @include breakpoint(M) {
            width: calc(100vw - 10rem);
        }
        @include breakpoint(S) {
            height: 24rem;
        }
        @include breakpoint(XS) {
            height: 10rem;
            width: calc(100vw - 4rem);
            margin: 1rem 0.5rem;
        }
        img {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: 1;
        }
        &:before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: 2;
            @extend %transition;
            background: linear-gradient(0deg, rgba(#000, 0.6), rgba(#000, 0.6));
            background-blend-mode: multiply, normal;
        }
    }
    .caption {
        width: 100%;
        padding: 2rem 0;
        text-align: center;
        z-index: 3;
        opacity: 0;
        @extend %nav-transition;
        transform: translateY(-0.5rem);

        @include breakpoint(XS) {
            bottom: 0.875rem;
            padding: 1.5rem 0;
        }
    }
    .gallery-carousel-slide {
        &.slick-active,
        &[aria-hidden="true"]:not([tabindex="-1"]) + .slick-cloned[aria-hidden="true"] {
            .gallery-carousel-slide-img {
                height: 35rem;
                max-height: 90vh;
                margin-top: 0;
                margin-bottom: 0;

                @include breakpoint(S) {
                    height: 29rem;
                }
                @include breakpoint(XS) {
                    height: 12rem;
                }
                &:before {
                    opacity: 0;
                }
            }
            .caption {
                opacity: 1;
                transform: translateY(0);
            }
        }
    }
}

.gallery-carousel-control {
    position: absolute;
    top: 0;
    height: 35rem;
    width: 8rem;
    z-index: 1;

    @include breakpoint(M) {
        width: 5rem;
    }
    @include breakpoint(S) {
        height: 29rem;
    }
    @include breakpoint(XS) {
        display: none;
    }
    &.gallery-carousel-prev {
        left: 0;
        cursor: url('data:image/svg+xml,<svg width="32" height="32" viewBox="0 0 76 76" fill="none" xmlns="http://www.w3.org/2000/svg"><rect width="72" height="72" rx="36" transform="matrix(-1 0 0 1 74 2)" fill="white"/><path d="M41.959 30.0833L34.0423 37.9999L41.959 45.9166" stroke="%23092F3C" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/><rect width="72" height="72" rx="36" transform="matrix(-1 0 0 1 74 2)" stroke="%23092F3C" stroke-width="2.5"/></svg>') 25 25, auto;
    }
    &.gallery-carousel-next {
        right: 0;
        cursor: url('data:image/svg+xml,<svg width="32" height="32" viewBox="0 0 76 76" fill="none" xmlns="http://www.w3.org/2000/svg"><rect x="2" y="2" width="72" height="72" rx="36" fill="white"/><path d="M34.041 30.0833L41.9577 37.9999L34.041 45.9166" stroke="%23092F3C" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/><rect x="2" y="2" width="72" height="72" rx="36" stroke="%23092F3C" stroke-width="2.5"/></svg>') 25 25, auto;
    }
}