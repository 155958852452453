@import "../gutenberg_block";

.featured-list {
    .featured-list-content-inner {
        position: sticky;
        top: 9rem;

        *:last-child {
            margin-bottom: 0;
        }
    }
    .featured-list-content {
        width: 40%;
        padding-top: 1.75rem;
        padding-bottom: 1.75rem;

        @include breakpoint(XS) {
            width: 100%;
            padding-top: 0;
        }
    }
    .featured-list-progress-wrap {
        width: 10%;
        overflow: hidden;
        height: calc(100vh + 2rem);
        position: sticky;
        top: 0;
    }
    .featured-list-progress {
        position: relative;
        height: 100vh;
        position: sticky;
        top: 0;

        &:after {
            content: "";
            top: 0;
            left: calc(50% - 0.5px);
            height: 100%;
            position: absolute;
            width: 1px;
            @include color(background-color, 'body-10');
        }
        .featured-list-progress-inner {
            height: 100%;
            position: absolute;
            top: 0;
            left: 50%;
            z-index: 2;
            transform: translateX(-50%);
        }
        .progress-indicator {
            @extend %transition;
            display: block;
            margin-left: -0.5rem;
            width: 1rem;
            height: 1rem;
            transform: rotate(45deg);
            @include color(background-color, 'accent-magenta');
            position: absolute;
            top: 0;
            z-index: 2;

            &:after {
                content: "";
                position: absolute;
                bottom: calc(100% - 2px);
                height: 100vh;
                width: 1px;
                left: 1px;
                @include color(background-color, 'accent-magenta');
                transform: rotate(-45deg);
                transform-origin: bottom left;
            }
        }
    }
    .featured-list {
        width: 50%;
        padding-top: 1.75rem;
        padding-bottom: 1.75rem;

        @include breakpoint(XS) {
            width: 90%;
            padding: 0 0 0 2rem;
        }
        .featured-list-item {
            margin-bottom: 3.5rem;

            &:last-child {
                margin-bottom: 0;
            }
        }
    }
}