@import "../gutenberg_block";

.accordion {
    margin: 0 auto;
    max-width: 40rem;

    .accordion-title {
        cursor: pointer;
        position: relative;
        @extend %transition;
        padding-left: 2rem;
        padding-right: 2rem;

        @include breakpoint(XS) {
            padding-left: 1.5rem;
            padding-right: 1.5rem;
        }
        &:focus {
            outline: 0;
        }
        &.has-icon {
            padding-right: 5rem;

            @include breakpoint(XS) {
                padding-right: 3.5rem;
            }
        }
        .icon {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            right: 2rem;
            border-radius: 100%;
            border: 2px solid;

            color: $color-accent-primary;
            border-color: $color-accent-primary;

            @extend %transition;
            width: 2.8333rem;
            height: 2.8333rem;
            display: flex;
            justify-content: center;
            align-items: center;

            @include breakpoint(XS) {
                right: 1.5rem;
                width: 2rem;
                height: 2rem;
            }
            path {
                @extend %transition;
            }
            svg {
                @extend %transition;

                @include breakpoint(XS) {
                    width: 1rem;
                    height: 1rem;
                }
            }
        }
        h3,
        h4,
        h5,
        p {
            font-style: normal;
            color: $color-accent-primary;
            margin: 0;
            padding: 2.444rem 0;
            @extend %transition;
            @include size-L;
            font-weight: normal;

            @include breakpoint(XS) {
                padding: 1.5rem 0;
            }
        }
        &.inner-padding {
            h3,
            h4,
            h5,
            p {
                padding: 1.5rem 5rem 1.5rem 1.5rem;
            }
            .icon {
                right: 1.5rem;
            }
        }
        &:hover {
            h3,
            h4,
            h5,
            p {
                color: $color-accent-magenta;
            }
            .icon {
                border-color: $color-accent-magenta;

                svg {
                    color: $color-accent-magenta;
                }
            }
        }
        &.open {
            h3,
            h4,
            h5,
            p {
                color: $color-accent-magenta;
            }
            .icon {
                border-color: $color-accent-magenta;

                svg {
                    color: $color-accent-magenta;
                    transform: rotate(180deg);
                }
            }
        }
    }
    .accordion-content {
        display: none;
        padding: 1.5rem 2rem;
        border-top: 1px solid;
        border-color: rgba($color-body, 0.1);

        @include breakpoint(XS) {
            padding: 1.5rem;
        }
        &.inner-accordion {
            padding: 1rem 3rem 1.5em 3rem;
        }
        p {
            max-width: none;
            color: $color-body;
        }
        p:last-child,
        ul:last-child {
            margin-bottom: 0;
        }
    }
    .accordion-group {
        box-shadow: 0px 4px 9px rgba($color-body, 0.1);
        margin-bottom: 1.5rem;
        border-radius: 4px;
        background: #fff;

        @include breakpoint(XS) {
            margin-bottom: 1rem;
        }
    }
    &.opened {
        .accordion-title {
            opacity: 0.25;

            &.open {
                opacity: 1;
            }
        }       
    }
}