@import "../gutenberg_block";

.grid-list {
  margin-left: -1rem;
  margin-right: -1rem;

  .grid-list-item {
    width: calc(16.666% - 2rem - 1px);
    margin: 1.5rem 1rem;
    text-align: center;
    flex-grow: 1;

    @include breakpoint(S) {
      width: calc(33.333% - 2rem - 1px);
    }
    @include breakpoint(XS) {
      width: calc(50% - 2rem - 1px);

      &:last-child {
        margin-bottom: 0;
      }
    }

    .list-counter {
      color: var(--color-accent-yellow);
      padding: 1.25rem;
      border: solid 2px var(--color-body-25);
      border-radius: 100%;
      width: 6rem;
      max-width: 100%;
    }

    .list-content,
    h5,
    p {
      width: 100%;
    }
  }
  &.mobile-scroll {
    @include breakpoint(XS) {
      margin-left: 0;
      margin-right: 0;

      .grid-list-item {
        width: calc(50vw - 2rem);
        flex-shrink: 0;
        margin: 0;
        padding: 0 1rem;
      }
    }
  }
}
