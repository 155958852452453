@import "../gutenberg_block";

.device-carousel-slide {
    margin: 0 3vw;
    max-width: 72rem;
    width: 66vw;

    @include breakpoint(S) {
        width: 75vw;
    }
    @include breakpoint(XS) {
        margin: 0;
    }
    &.slick-active,
    &[aria-hidden="true"]:not([tabindex="-1"]) + .slick-cloned[aria-hidden="true"] {
        .device {
            transform: scale(1);
        }
        &.desktop-mobile {
            .mobile {
                bottom: 1.75rem;
            }
        }
    }
    .centered-content {
        p {
            font-family: $font-stack-headings;
            @include color(color, 'accent-primary');
        }
    }
}

.device-carousel-devices {
    &.mobile {
        display: flex !important;

        .mobile {
            &:nth-child(2) {
                transition-delay: 0.4s;
            }
            &:nth-child(3) {
                transition-delay: 0.8s;
            }
        }
    }
    &.desktop-mobile {
        position: relative;
        padding-bottom: 3.5rem;
        padding-right: 1.75rem;

        .mobile {
            width: 14rem;
            position: absolute;
            bottom: 0;
            right: 0;
            z-index: 2;
            margin: 0;
            transition-delay: 0.4s;

            @include breakpoint(M) {
                width: 9rem;
            }
            @include breakpoint(S) {
                width: 8rem;
            }
            @include breakpoint(XS) {
                width: 6rem;
            }
        }
    }
    .device {
        transform: scale(0.9);
        @extend %nav-transition;
    }
}

.device {
    border: 2px solid;
    border-radius: 1rem;
    @include color(border-color, 'body');
    overflow: hidden;

    @include breakpoint(XS) {
        border-radius: 0.5rem;
    }
    img {
        width: 100%;
        height: auto;
        display: block;
        max-height: 100vh;
    }
    &.desktop {
        .desktop-top {
            padding: 1rem 1.5rem;
            border-bottom: 2px solid;
            @include color(border-color, 'body');
            @include color(background-color, 'background');

            @include breakpoint(M) {
                padding: 0.875rem;
            }
            @include breakpoint(XS) {
                padding: 0.333rem 0.5rem;
            }
            .control {
                display: block;
                border: 2px solid;
                @include color(border-color, 'body');
                width: 1.333rem;
                height: 1.333rem;
                border-radius: 100%;
                margin-right: 0.5rem;

                @include breakpoint(M) {
                    width: 1rem;
                    height: 1rem;
                }
                @include breakpoint(XS) {
                    width: 0.5rem;
                    height: 0.5rem;
                    margin-right: 0.25rem;
                }
            }
        }
    }
    &.mobile {
        width: 18rem;
        max-width: 33.333%;
        flex-grow: 1;
        margin: 0 1.75rem;
        @extend %transition;

        @include breakpoint(S) {
            margin: 0 0.5rem;
        }
        .mobile-top {
            padding: 0.666rem 0;
            border-bottom: 2px solid;
            @include color(border-color, 'body');
            @include color(background-color, 'background');

            @include breakpoint(XS) {
                padding: 0.333rem 0;
            }
            .camera {
                display: block;
                border: 2px solid;
                @include color(border-color, 'body');
                width: 0.5rem;
                height: 0.5rem;
                border-radius: 100%;
                margin-right: 0.5rem;
            }
            .speaker {
                display: block;
                border: 2px solid;
                @include color(border-color, 'body');
                width: 3rem;
                height: 0.5rem;
                border-radius: 0.5rem;

                @include breakpoint(M) {
                    width: 2.5rem;
                }
                @include breakpoint(XS) {
                    width: 1.5rem;
                }
            }
        }
        .mobile-bottom {
            padding: 0.5rem 0;
            border-top: 2px solid;
            @include color(border-color, 'body');
            @include color(background-color, 'background');

            @include breakpoint(M) {
                padding: 0.25rem 0;
            }
            .thumb {
                display: block;
                border: 2px solid;
                @include color(border-color, 'body');
                width: 2.333rem;
                height: 2.333rem;
                border-radius: 100%;

                @include breakpoint(M) {
                    width: 1.5rem;
                    height: 1.5rem;
                }
                @include breakpoint(XS) {
                    width: 1.25rem;
                    height: 1.25rem;
                }
            }
        }
    }
}

.device-carousel-control {
    position: absolute;
    top: 0;
    height: 35rem;
    width: 8rem;
    z-index: 1;

    @include breakpoint(M) {
        width: 5rem;
    }
    @include breakpoint(S) {
        height: 29rem;
    }
    @include breakpoint(XS) {
        display: none;
    }
    &.device-carousel-prev {
        left: 0;
        cursor: url('data:image/svg+xml,<svg width="32" height="32" viewBox="0 0 76 76" fill="none" xmlns="http://www.w3.org/2000/svg"><rect width="72" height="72" rx="36" transform="matrix(-1 0 0 1 74 2)" fill="white"/><path d="M41.959 30.0833L34.0423 37.9999L41.959 45.9166" stroke="%23092F3C" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/><rect width="72" height="72" rx="36" transform="matrix(-1 0 0 1 74 2)" stroke="%23092F3C" stroke-width="2.5"/></svg>') 25 25, auto;
    }
    &.device-carousel-next {
        right: 0;
        cursor: url('data:image/svg+xml,<svg width="32" height="32" viewBox="0 0 76 76" fill="none" xmlns="http://www.w3.org/2000/svg"><rect x="2" y="2" width="72" height="72" rx="36" fill="white"/><path d="M34.041 30.0833L41.9577 37.9999L34.041 45.9166" stroke="%23092F3C" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/><rect x="2" y="2" width="72" height="72" rx="36" stroke="%23092F3C" stroke-width="2.5"/></svg>') 25 25, auto;
    }
}