@import "../gutenberg_block";

.creative-archive {
    padding-top: 9rem;
    padding-bottom: 9rem;
    @extend %transition;

    header {
        position: relative;
        z-index: 3;

        @include breakpoint(XS) {
            text-align: center;
        }
        h1,
        p {
            @extend %transition;
        }
    }
}

.archive-item {
    position: relative;
    border-bottom: 1px solid;
    @include color(border-color, 'body-25');
    padding: 2.222rem 3rem 2.222rem 0;
    @extend %transition;

    @include breakpoint(XS) {
        padding: 1.125rem 3rem 1.125rem 0;
    }
    &:hover {
        cursor: pointer;
    }
    .archive-item-arrow {
        position: absolute;
        right: 0.5rem;
        top: 50%;
        transform: translateY(-50%);
        @extend %nav-transition;
        opacity: 0;
        width: 2rem;
        height: 2rem;
        @include color(color, 'body');

        @include breakpoint(XS) {
            opacity: 1;
            transform: translateY(-50%) translateX(0.5rem);
        }
    }
    &:hover {
        .archive-item-arrow {
            opacity: 1;
            transform: translateY(-50%) translateX(0.5rem);
        }
    }
    .year {
        width: 5.5rem;
        flex-shrink: 0;

        @include breakpoint(XS) {
            display: none;
        }
    }
    .title {
        width: 20rem;
        flex-shrink: 0;
        padding-right: 2rem;

        @include breakpoint(S) {
            width: 14rem;
        }
        @include breakpoint(XS) {
            width: 100%;
        }
    }
    .description {
        flex-grow: 1;

        @include breakpoint(XS) {
            width: 100%;
        }
    }
    p,
    h4 {
        @extend %transition;
    }
}

.year-wrap {
    .archive-item {
        .year {
            opacity: 0;
            visibility: hidden;
        }
        &:nth-child(2) {
            .year {
                opacity: 1;
                visibility: visible;
            }
        }
    }
}

.archive-items {
    &:hover {
        .archive-item {
            opacity: 0.5;

            &:hover {
                opacity: 1;
            }
        }       
    }
}

.creative-archive-bg {
    opacity: 0;
    visibility: hidden;
    @extend %transition;

    &:after {
        background: linear-gradient(90deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.25) 25.83%, rgba(0, 0, 0, 0.25) 74.17%, rgba(0, 0, 0, 0) 100%), linear-gradient(0deg, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6));
        background-blend-mode: multiply, multiply, normal;
    }
    img {
        filter: none;
    }
    &.active {
        opacity: 1;
        visibility: visible;
    }
}

.mob-year {
    display: none;
    padding-top: 3rem;

    @include breakpoint(XS) {
        display: block;
    }
}

.creative-archive-single-block {
    height: 100%;
    width: 100%;
    position: relative;
    padding: 6rem 0 5rem 5rem;

    @include breakpoint(S) {
        padding: 7rem 0 3rem 1.75rem;
        overflow: auto;
    }
    @include breakpoint(XS) {
        padding-bottom: 0;
        overflow: scroll;
    }
    .creative-archive-single-block-content {
        width: 35vw;
        padding-right: 5.333rem;
        flex-shrink: 0;

        @include breakpoint(S) {
            width: 100%;
            padding-right: 1.75rem;
        }
        @include breakpoint(XS) {
            padding-bottom: 3rem;
        }
    }
    .page-bg-img {
        &:after {
            background: linear-gradient(90deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.25) 25.83%, rgba(0, 0, 0, 0.25) 74.17%, rgba(0, 0, 0, 0) 100%), linear-gradient(0deg, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6));
            background-blend-mode: multiply, multiply, normal;
        }
        img {
            filter: blur(10px);
        }
    }
    .creative-archive-single-block-images {
        width: calc(65vw - 5rem);

        @include breakpoint(S) {
            width: 100%;
            margin-bottom: 3rem;
        }
        img,
        video {
            height: 30rem;
            max-height: 60vh;
            width: auto;
            max-width: calc(65vw - 7.75rem);
            margin-right: 1rem;
            border-radius: 0.25rem;
            object-fit: contain;

            @include breakpoint(M) {
                height: 18rem;
            }
            @include breakpoint(S) {
                height: 24rem;
                width: calc(100vw - 7.75rem);
                max-width: none;
            }
            @include breakpoint(XS) {
                height: 14rem;
                width: calc(100vw - 3.75rem);
            }
        }
    }
    .close {
        position: absolute;
        top: 2rem;
        right: 2rem;
        z-index: 2;
        @include color(color, 'body');

        @include breakpoint(S) {
            right: 1.75rem;
        }
        &.is-archive {
            display: none;
        }
        svg {
            display: block;
            @extend %transition;
        }
        &:hover {
            svg {   
                transform: rotate(180deg);
            }
        }
    }
    .breadcrumbs {
        position: absolute;
        top: 2.222rem;
        left: 5rem;

        @include breakpoint(S) {
            left: 1.75rem;
            top: 2.5rem;
        }
        .slash {
            opacity: 0.5;
            margin: 0 0.5rem;
        }
        a {
            font-weight: normal;
            @include color(color, 'body');
            opacity: 0.5;

            &:hover {
                opacity: 1;
            }
        }
    }
    footer {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        padding: 1.222rem 5rem;
        border-top: 1px solid;
        @include color(border-color, 'body-50');

        @include breakpoint(S) {
            padding: 1rem 1.75rem;
        }
        @include breakpoint(XS) {
            position: static;
            margin-left: -1.75rem;
            padding: 0.5rem 0;
            flex-shrink: 0;
        }
        &.is-archive {
            display: none;
        }
        a {
            @include color(color, 'body');
            font-weight: 400;
            display: flex;
            align-items: center;
            opacity: 0.5;

            @include breakpoint(XS) {
                padding: 0.5rem 0;
            }
            strong {
                font-weight: 700;
                text-transform: none;
            }
            svg {
                @extend %transition;
            }
            &:hover {
                opacity: 1;
            }
        }
        .prev-project {
            svg {
                margin-right: 1rem;
            }
            strong {
                margin-right: 0.5rem;
            }
            a:hover {
                svg {
                    transform: translateX(-0.5rem);
                }
            }
        }
        .next-project {
            svg {
                margin-left: 1rem;
            }
            strong {
                margin-left: 0.5rem;
            }
            a:hover {
                svg {
                    transform: translateX(0.5rem);
                }
            }
        }
    }
}

.single-creative-archive {
    .header,
    .footer {
        display: none;
    }
}

.creative-archive-controls {
    padding-top: 1.333rem;
    padding-right: 3rem;

    @include breakpoint(S) {
        padding-right: 1.75rem;
    }
    a {
        @include color(color, 'body');

        svg {
            display: block;
        }
        &:hover {
            @include color(color, 'accent-magenta');
        }
    }
}

.creative-archive-counter {
    margin: 0 1rem;

    .slash {
        margin: 0 0.25rem;
    }
}

.archive-modal {
    @include color(background-color, 'accent-primary');

    .creative-archive-single-block {
        .close {
            &.is-single {
                display: none;
            }
            &.is-archive {
                display: block;
            }
        }
        footer {
            &.is-single {
                display: none;
            }
            &.is-archive {
                display: flex;
            }
        }
    }
}

.work-awards {
    .award {
        padding: 1rem 0;
        @include color(color, 'body');
        border-bottom: 1px solid;
        @include color(border-color, 'body-10');

        span {
            display: block;
        }
        &:last-child {
            border: 0;
        }
        img,
        svg {
            width: 3rem;
            height: 2rem;
            margin-right: 1.3888rem;
        }
        svg {
            color: #fff;
        }
    }
}