@import "../gutenberg_block";

.logo-carousel-item {
    text-align: center;
    padding: 0 0.25rem;
    
    @include breakpoint(XS) {
        padding: 0 1.5rem;
        width: 10rem;
    }
    img,
    svg {
        width: 7rem;
        height: 4rem;
        display: block;
        margin: 0 auto 1.5rem auto;
    }
    svg {
        @include color(color, 'body');
    }
}