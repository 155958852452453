p {
    @include size-M;
    font-family: $font-stack-primary;
    margin-bottom: 2rem;
    @include color(color, 'body');
    font-weight: 400;

    &:has(+ p) {
        margin-bottom: 1rem;
    }

    &.no-margin {
        margin-bottom: 0;
    }
    &.less-margin {
        margin-bottom: 0.5rem;
    }
    &.quote {
        &:before {
            content: open-quote;
        }
        &:after {
            content: close-quote;
        }
    }
}

p.tagline {
    margin: 0;
    @include color(color, 'accent-primary');
}

em,
strong,
b {
    font-weight: 700;
    text-transform: uppercase;
    @include color(color, 'body');
}

.bold {
    font-weight: 700;
}

.font-headings {
    strong {
        text-transform: unset;
    }
}