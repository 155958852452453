//Margins and Vertical Rhythm

.page-content-inner,
.content-element,
.rhythm {
    > * {
        margin-bottom: $unit * 6;

        &:last-child {
            margin-bottom: 0;
        }
    }

    > p + p,
    >h2 + p,
    >h3 + p,
    >h4 + p,
    >h5 + p,
    >h6 + p,
    > .content-wrap + .buttons {
        margin-top: $unit * -3;
    }

    //Max width for certain elements in content sections to force correct character per line

    p, blockquote, li {
        max-width: 48em;
    }

}

section.row {
    + {
        h1,
        h2,
        h3,
        h4,
        h5,
        h6,
        p,
        ul,
        ol {
            margin-top: 6rem;
        }   
    }
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
ul,
ol {
    + {
        section.row {
            margin-top: 6rem;
        }
    }
}   