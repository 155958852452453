@import "../gutenberg_block";

.container.work-grid-container {
    @media all and (max-width: 1000px) {
        padding-left: 0;
        padding-right: 0;
    }
    @include breakpoint(M) {
        padding-bottom: 0;
    }
}

.work-link {
    float: left;
    width: calc(25% - 2rem - 1px);
    padding-bottom: calc(25% - 2rem - 1px);
    position: relative;
    margin: 1rem;

    @media all and (max-width: 1000px) {
        float: none;
        width: 100%;
        padding: 0;
        margin: 0;
    }
    &:hover {
        strong {
            @include color(color, 'accent-magenta');
        }
    }
    .work-link-inner {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        .work-seperate{
            width: 100%;
            flex-wrap: wrap;
            justify-content: space-evenly;
          
            strong {
                @extend %transition;
                font-size: 5rem;
                text-align: center;
                line-height: 1;
                width: 50%;
        
                @include breakpoint(M) {
                    font-size: 3rem;
                }
                @media all and (max-width: 1000px) {
                    font-size: 3.375rem;
                    width: unset;
                }
            }
        }

        @media all and (max-width: 1000px) {
            position: static;
            flex-direction: row;
            justify-content: space-between;
            padding: 1.5rem 3.125rem 0 3.125rem;

            .work-seperate{
                gap: 1.06rem;
                width: unset;
            }
        }
    }
   
}

.work-items {
    .slick-track {
        display: flex;
        gap: 2rem;
    }

    @include breakpoint(M) {
        padding-bottom: 1rem;
    }

    &.offset-top {
        margin-top: -8.5rem;
        position: relative;
        z-index: 2;
        @include breakpoint(S) {
            margin-top: -5rem;
        }
    }

    &.cta-blocks {
        @include breakpoint(M) {
            margin-left: 0;
            margin-right: 0;
        }
    }
    
    .cta {
        .cs {
            padding-left: 0.125rem;
        }
        @include breakpoint(M) {
            &.quarter .cta-inner {
                padding: 1rem;

                .cs {
                    display: none;
                }
                .button {
                    display: none;
                }
            }
        }
        @include breakpoint(M) {
            width: calc(100vw - 7rem);
            min-height: 0;
            padding: 0;
            flex-grow: unset;
            aspect-ratio: 16/9;
            height: auto;

            .cta-inner,
            &.quarter .cta-inner {
                padding: 1.5rem;
            }
            &.quarter .cta-inner {
                .cs {
                    display: block;
                }
            }
            .button {
                display: flex !important;
                opacity: 1;
                visibility: visible;
                padding-top: 0.5rem;
                max-height: none !important;
            }
            p {
                @include size-M;
                margin: 0;
            }
            h3,
            h4 {
                @include size-XL;
                margin-bottom: 0.25rem;
            }
        }
        @include breakpoint(XS) {
            margin: 0 0.5rem;
            width: calc(100vw - 3.5rem);
        }
    }

    //Grid layout on desktop
    @include breakpoint(M-up) {
        display: grid;
        grid-template-columns: repeat(4, minmax(0, 1fr));
        grid-auto-flow: row dense;
        gap: 1.5rem;

        .cta {
            width: 100%;

            &:after {
                border-bottom-left-radius: 0;
                border-bottom-right-radius: 0;
            }

            &:first-child {
                grid-column: span 2;
                grid-row: span 2;
            }

            p {
                margin-inline: 0;
            }
        }

        .work-link {
            width: 100%;
            padding-bottom: 0;
            margin: 0;

            .work-seperate {
                width: 11rem;
            }
        }
    }
}