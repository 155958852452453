@import "../gutenberg_block";

.team-grid {
    display: grid;
    grid-template-columns: 1fr 1fr;

    @include breakpoint(S-up) {
        grid-template-columns: 1fr 1fr 1fr;
    }

    .team-member {
        aspect-ratio: 3/4;
        position: relative;
        z-index: 0;
        @extend %nav-transition;


        img {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            filter: grayscale(1) contrast(0.75)
        }
        .team-inner {
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            padding: 0 1.5rem;
            z-index: 3;
            opacity: 0;
            @extend %nav-transition;
        }
        &:after {
            content: "";
            z-index: 2;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            @extend %nav-transition;
            @include color(background-color, 'background');
            mix-blend-mode: soft-light;
        }
        &:hover {
            z-index: 2;
            box-shadow: 0px 4px 90px var(--color-background), 0px 9px 18px var(--color-background);

            .team-inner {
                opacity: 1;
                transform: translateY(-1.333rem);
            }
            &:after {
                background: linear-gradient(180deg, rgba(0, 0, 0, 0) 64.06%, rgba(0, 0, 0, 0.5) 100%), #0E566E;
            }
        }
    }
}

html.touchevents {
    @include breakpoint(M) {
        .team-inner {
            opacity: 1;
            transform: translateY(-1.333rem);
        }
    }   
}