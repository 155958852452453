// Fonts
$font-stack-primary: 'Graphik', Inter, Roboto, 'Helvetica Neue', 'Arial Nova', 'Nimbus Sans', Arial, sans-serif;
$font-stack-headings: 'Tiempos Headline', Charter, 'Bitstream Charter', 'Sitka Text', Cambria, serif;

$font-stack-system: $font-stack-primary;

// General Breakpoints
$breakpoints: (
    'XXS': ( max-width:  30rem ),
    'XS': ( max-width:  40rem ),
    'S': ( max-width:  50rem ),
    'MS': ( max-width:  59rem ),
    'M': ( max-width:  64rem ),
    'ML': ( max-width:  75rem ),
    'L': ( max-width: 91rem ),
    'XL': ( min-width: 91rem ),
    'XXL': ( min-width: 105rem ),
    'XXXL': ( min-width: 112rem ),
    'XXS-up': ( min-width: calc(30rem + 1px) ),
    'XS-up': ( min-width:  calc(40rem + 1px) ),
    'S-up': ( min-width: calc(50rem + 1px) ),
    'MS-up': ( min-width:  calc(59rem + 1px) ),
    'M-up': ( min-width:  calc(64rem + 1px) ),
    'ML-up': ( min-width:  calc(75rem + 1px) ),
    'L-up': ( min-width: calc(91rem + 1px) )
);
