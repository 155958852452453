$unit: 0.5rem;

//Shortcut for Media Queries
@mixin breakpoint($name) {
  @if map-has-key($breakpoints, $name) {
    @media #{inspect(map-get($breakpoints, $name))} {
      @content;
    }
  }
  @else {
    @warn "Couldn't find a breakpoint named `#{$name}`.";
  }
}

//Shortcut for targetting SVG path types
@mixin svg-paths {
  path,
  circle,
  polygon,
  line,
  polyline,
  rect {
    @content;
  }
}

//Allows hex colours in Data URIs
@function url-friendly-colour($colour) {
  @return '%23' + str-slice('#{$colour}', 2, -1);
}

//Generates vendor prefixes for placeholders
@mixin placeholder {
  &::-webkit-input-placeholder {@content};
  &:-moz-placeholder           {@content};
  &::-moz-placeholder          {@content};
  &:-ms-input-placeholder      {@content};
}

@mixin rhythm {
  > * {
    margin-bottom: $unit * 6;
  }

  > p + p,
  >h2 + p,
  >h3 + p,
  >h4 + p,
  >h5 + p,
  >h6 + p {
      margin-top: $unit * -3;
  }

  //Max width for certain elements in content sections to force correct character per line

  p, blockquote, li {
      max-width: 48em;
  }
}

@function map-deep-get($map, $keys...) {
  @each $key in $keys {
      $map: map-get($map, $key);
  }
  @return $map;
}

//Math function for indices (ie. multiply a value by itself)
@function pow($number, $exponent) {
  $value: 1;

  @if $exponent > 0 {
      @for $i from 1 through $exponent {
      $value: $value * $number;
      }
  }

  @return $value;
}