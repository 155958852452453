@import "../gutenberg_block";

.ota-calc-container:first-child {
    padding-top: 11rem;

    @include breakpoint(XS) {
        padding-top: 7rem;
    }
}

.ota-calculator {
    .ota-content {
        width: 50%;
        padding-right: 6rem;

        @include breakpoint(S) {
            width: 40%;
            padding-right: 3rem;
        }
        @include breakpoint(XS) {
            padding-right: 0;
            width: 100%;
            text-align: center;
            margin-bottom: 3rem;
        }
    }
}

.ota-icon {
    width: 12rem;

    @include breakpoint(XS) {
        margin-left: auto;
        margin-right: auto;
    }
    svg {
        display: block;
        @include color(color, 'body');
    }
}

.ota-form {
    width: 50%;
    border: 2px solid;
    border-radius: 0.25rem;
    @include color(border-color, 'body');

    @include breakpoint(S) {
        width: 60%;
    }
    @include breakpoint(XS) {
        width: 100%;
    }
    input {
        border: 0;
        background: transparent;
        padding: 0;
        border-radius: 0;
        margin: 0;
        -webkit-appearance: none;
        -moz-appearance: textfield;
        font-family: $font-stack-headings;
        @include size-XL;
        @include color(border-color, 'body');
        
        &::-webkit-outer-spin-button,
        &::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
        }
    }
    .percentage-input {
        input {
            width: 2ch;

            &.hundreds {
                width: 3ch;
            }
        }
        .percent {
            opacity: 0.5;
        }
    }
    label {
        display: block;
        margin-bottom: 0.25rem;
    }
    .controls {
        position: absolute;
        right: 1rem;
        bottom: 0.75rem;
        z-index: 3;
        opacity: 0;
        visibility: hidden;
        @extend %transition;
        transform: translateY(0.25rem);

        button {
            margin: 0;

            &:first-child {
                margin-right: 0.444rem;
            }
        }
    }
    .half {
        padding: 0.888rem 1.222rem 0.75rem 1.222rem;
        position: relative;

        &:hover {
            .controls {
                opacity: 1;
                visibility: visible;
                transform: translateY(0);
            }
        }
    }
    .half:first-child {
        border-right: 1px solid;
        @include color(border-color, 'body');

        @include breakpoint(XS) {
            border-right: 0;
            margin: 0;
            border-bottom: 2px solid;
        }
    }
    .half:last-child {
        border-left: 1px solid;
        @include color(border-color, 'body');

        @include breakpoint(XS) {
            border-left: 0;
            margin: 0;
            border-bottom: 2px solid;
        }
    }
    .row {
        margin: 0;
        border-bottom: 2px solid;
        @include color(border-color, 'body');

        &:last-child {
            border: 0;
        }
        @include breakpoint(XS) {
            border: 0;
            margin: 0;
        }
    }
    .OTAResult {
        text-align: center;
    }
    .total_ota_spend {
        padding: 1.222rem;
        background: $color-accent-magenta;
        
        p {
            color: #fff;
        }
    }
    .lost_in_adr {
        padding: 1.222rem;
        background: $color-error;
        
        p {
            color: #fff;
        }
    }
    .shift_to_save {
        padding: 2.222rem 1rem;
        background: $color-success;
        margin: 0;

        p {
            color: #fff;
        }
    }
}