table {
    width: 100%;
    margin-bottom: 2rem;
    text-align: left;

    th {
        @include color(color, 'body');
        font-weight: 700;
        padding: $unit * 2;
        border-bottom: 1px solid;
        @include color(border-color, 'body-10');
        min-width: 10rem;
    }
    tr {
        td {
            padding: $unit ($unit * 2);
            min-width: 10rem;

            > * {
                &:last-child {
                    margin-bottom: 0;
                }
            }
        }
    }
    tfoot {
        td {
            padding: 1.5rem 1rem;
            font-weight: 700;
            border: 2px solid;

            @include color(background-color, 'accent-primary');
            @include color(color, 'accent-reverse');
            @include color(border-color, 'body');
        }
    }
    @include breakpoint(XS) {
        overflow-x: scroll;
        display: block;
    }
}

    