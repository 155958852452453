@import "../gutenberg_block";

.intro-grafic {
    display: flex;
    position: relative;
    padding-top: 3.375rem;

    @include breakpoint(XS) {
        flex-direction: column;
    }
    &.slick-slide {
        display: flex !important;
    }
    .content {
        width: 60%;
        padding: 0;
        flex-grow: 1;
        flex-basis: 0;
        display: flex;
        justify-content: center;
        align-items: center;

        @include breakpoint(XS) {
            padding: 0 0 3rem 0;
            margin: 0;
            width: 100%;
            text-align: center;
            flex-basis: auto;
            order: 1;

            hr {
                margin-left: auto;
                margin-right: auto;
            }
            .buttons {
                justify-content: center;
                margin-bottom: 0;
            }
        }
        .content-inner {
            width: 100%;
            display: flex;
            flex-direction: column;
            .overline{
                @include size-S;
                font-weight: 700;
                line-height: 22.5px; /* 160.714% */
                letter-spacing: 0.875px;
                text-transform: uppercase;
            }
            .content-wrap p{
                @include size-2XL;
                font-family: $font-stack-headings;
                font-weight: 400;
                line-height: 45px;
                strong{
                    text-transform: unset;
                }
            }

            @include breakpoint(XS) {
                text-align: left;
            }
        }
    }
    .img {
        position: relative;
        width: calc(40% - 8.62rem);
        position: relative;
        margin-left: 0;
        margin-right: 8.62rem;

        .image-carousel {
            // min-height: 30rem;
            aspect-ratio: 1;

            // @include breakpoint(XS) {
            //     min-height: 16rem;
            // }
            // &.shorter {
            //     min-height: 24rem;

            //     @include breakpoint(XS) {
            //         min-height: 16rem;
            //     }
            // }
        }
        @include breakpoint(XS) {
            width: 60%;
            order: 2;
            margin: 0;
        }
        img {
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            width: 100%;
        }
        .slick-list,
        .slick-track {
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            width: 100%;
        }
        .slick-controls {
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            padding: 2rem 0 1rem 0;
            background: linear-gradient(0deg, rgba(#000, 0.6), rgba(#000, 0));

            .slick-control {
                color: #fff;

                &:hover {
                    @include color(color, 'accent-magenta');
                }
            }
        }
    }
    &.text-image {
        .content {
            order: 1;
            padding: 0;

            @include breakpoint(XS) {
                padding: 0 0 3rem 0;
                display: block;
                margin: 0;
                order: 1;
            }
        }
        .img {
            order: 2;
            margin-right: 0;
            margin-left: 8.62rem;

            @include breakpoint(XS) {
                order: 2;
                width: 60%;
                margin: 0;
            }
        }
    }
    &.mob-img-bottom {
        @include breakpoint(XS) {
            .content {
                order: 1;
            }
            .img {
                order: 2;
            }
        }
    }
}

.graphic-content {
    .img {
        &.graphic {
            svg {
                @include color(color, 'accent-primary');
                width: auto;
                min-height: 18rem;
                max-width: 20rem;
                height: 100%;
                margin: 0 auto;
                display: block;
            }
            &-align-top {
                svg {
                    transform: translateY(-4rem);
                    
                    @include breakpoint(XS) {
                        transform: none;
                    }
                }
            }
            &-align-bottom {
                svg {
                    transform: translateY(4rem);
                    
                    @include breakpoint(XS) {
                        transform: none;
                    }
                }
            }
        }
    }
    .content {
        p {
            @include color(color, 'accent-primary');

            em,
            strong,
            b {
                @include color(color, 'accent-primary');
            }
        }
        .content-wrap.large {
            p {
                @include size-XL;
                font-family: $font-stack-headings;
            }
        }
    }
}
.spacing.section-intro-graphic:first-child {
    padding-top: 9rem;

    @include breakpoint(XS) {
        padding-top: 4.5rem;
    }
}

.button.icon.content-carousel-prev,
.button.icon.content-carousel-next {
    position: absolute;
    right: 2rem;
    margin: 0;

    @include breakpoint(M) {
        right: 1rem;
    }
}

.button.icon.content-carousel-prev {
    top: calc(50% - 3.25rem);

    @include breakpoint(S) {
        left: calc(50% - 3.25rem);
        top: auto;
        bottom: 2rem;
    }
}

.button.icon.content-carousel-next {
    bottom: calc(50% - 3.25rem);

    @include breakpoint(S) {
        right: calc(50% - 3.25rem);
        top: auto;
        bottom: 2rem;
    }
}

.content-carousel-container {
    @include breakpoint(S) {
        padding-bottom: 7rem;
    }
}