@import "../gutenberg_block";

.images-ticker-item {
    margin-left: 1.75rem;
    margin-right: 1.75rem;
    padding-top: 2rem;
    padding-bottom: 2rem;

    @include breakpoint(S) {
        margin-left: 0.75rem;
        margin-right: 0.75rem;
        padding-top: 0.75rem;
        padding-bottom: 0.75rem;
    }
    .images-ticker-item-inner {
        @extend %nav-transition;
    }
    &:nth-child(odd) {
        .images-ticker-item-inner {
            transform: translateY(-2rem);

            @include breakpoint(S) {
                transform: translateY(-0.75rem);
            }
        }
    }
    &:nth-child(even) {
        .images-ticker-item-inner {
            transform: translateY(2rem);

            @include breakpoint(S) {
                transform: translateY(0.75rem);
            }
        }
    }
    img {
        width: 14rem;
        height: 14rem;
        border-radius: 0.25rem;
        box-shadow: 0px 4px 9px rgba(9, 47, 60, 0.1);

        @include breakpoint(S) {
            width: 10rem;
            height: 10rem;
        }
    }
}

.images-ticker {
    &:hover {
        &:nth-child(odd),
        &:nth-child(even) {
            .images-ticker-item-inner {
                transform: translateY(0);
                
                @include breakpoint(S) {
                    transform: translateY(0);
                }
            }
        }
    }
}