@import "../gutenberg_block";

.cta {
    &.graphic {
        text-align: center;
        background: transparent;
        border: 1px solid;
        @include color(border-color, 'body-10');
        max-width: 24rem;

        &:after {
            display: none;
        }
        .graphic {
            svg {
                @include color(color, 'accent-primary');
                max-width: 100%;
                width: 10rem;
                height: 6rem;
                display: block;
                margin: 0 auto 1rem auto;

                @include breakpoint(M) {
                    height: 4.5rem;
                }
            }
        }
        .cta-inner {
            @include breakpoint(M) {
                justify-content: space-between;
            }
        }
        .cta-icon {
            width: 6rem;
            height: 6rem;
            border-radius: 100%;
            border: 2px solid;
            @extend %transition;
            @include color(border-color, 'body');
            
            svg {
                @include color(color, 'body');
                width: 3.222rem;
                height: 3.222rem;

                @include breakpoint(M) {
                    width: 2.5rem;
                    height: 2.5rem;
                }
            }
        }
        svg,
        h3,
        p {
            @extend %transition;
            @include color(color, 'accent-primary');
        }
        h3 {
            @include breakpoint(M) {
                @include size-L;
            }
        }
        p {
            @include size-M;

            @include breakpoint(M) {
                @include size-S;
            }
        }
        p:last-child {
            margin: 0;
        }
    }
}

a.cta {
    &.graphic {
        @extend %transition;
        
        &:hover {
            box-shadow: 0px 4px 9px rgba($color-body, 0.1);
            background: #fff;
            transform: translateY(-0.5rem);

            .icon,
            .cta-icon {
                @include color(border-color, 'accent-magenta');
            }
            svg,
            h3,
            p {
                @include color(color, 'accent-magenta');
            }
        }
    }
}