hr {
    border: 0;
    margin-left: 0;
    margin-right: 0;
    margin-bottom: 1rem;
    width: 3rem;
    border-bottom: 2px solid;
    @include color(border-color, 'accent-primary');

    &.centered {
        margin-left: auto;
        margin-right: auto;
    }
}