@import "../gutenberg_block";

.projects-grid-section{
    .project-items{
        .cta-project-single{
            transform: scale(1);
            @extend %transition;
            // .cta-inner{
            //     padding-bottom: 0;
            //     header{
            //         padding-bottom: 1.688rem;
            //     }
            // }
            &.cta-hide{
                transform: scale(.2);
                @extend %transition;
            }
        }
        @include breakpoint(S) {
            justify-content: space-between;
            .cta-project-single{
                flex: calc(50% - 0.5rem) 0 0 !important;
            }
        }
    }
    .filter-projects{
        display: flex;
        position: sticky;
        top: 1rem;
        z-index: 5;
        gap: 0.5rem;
        padding: 0.5rem;
        @include color(background, 'background');
        width: fit-content;
        border-radius: 50px;
        margin: auto;
        @extend %nav-transition;
        .link-projects{
            padding: 0.563rem 1.688rem;
            border-radius: 25px;
            @include color(color, 'body');
            @include color(background, 'background');
            border: 1px solid;
            @include color(border-color, 'body');
            font-style: normal;
            font-weight: 400;
            font-family: $font-stack-primary;
            @include size-S;
            @extend %nav-transition;
            &.active{
                @include color(background, 'body');
                @include color(color, 'background');
                @extend %nav-transition;
            }
            &:hover{
                @include color(background, 'body');
                @include color(color, 'background');
                @extend %nav-transition;
            }
        }
        @include breakpoint(XS) {
            top: 0.5rem;
            .link-projects{
                padding: 0.5rem 0.8rem
            }
        }
    }

    .project-items{
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        margin-top: 2.938rem;
        margin-bottom: 2.438rem;
        .cta{
            height: auto;
            padding-bottom: unset;
            aspect-ratio: 349 / 440;
            border-radius: 9px;
            flex: calc(33.333% - 2rem - 1px) 0 0;
            .cta-inner{
                header{
                    h3{
                        @include color(color, 'body');
                        @extend %transition;
                    }
                    .type{
                        display: flex;
                        flex-direction: row;
                        gap: .5rem;
                        span{
                            @include size-XS;
                            @include color(color, 'body');
                            font-weight: 400;
                            @extend %transition;
                        }
                    }
                }
                .sub-content{
                    @include size-S;
                    @include color(color, 'body');
                    // opacity: 0;
                    // visibility: hidden;
                    transition: all 0.3s ease;
                    overflow: hidden;
                    font-weight: 400;
                    max-height: 0;
                    padding-top: 0;
                    // transition: max-height 0.5s ease-out;
                    .button{
                        padding: 0;
                        margin: 0;
                        justify-content: left;
                        margin-top: 1.688rem;
                    }
                }
            }
            .dummy-project{
                content: "";
                width: 100%;
                height: 100%;
                @include color(background-color, 'background');
                opacity: 0;
                visibility: hidden;
                z-index: 3;
                position: absolute;
                border: 1px solid;
                @include color(border-color, 'accent-primary');
                border-radius: 9px;
                @extend %transition;
            }
            &:hover{
                .cta-inner{
                    header{
                        h3{
                            color: $color-background !important;
                            @extend %transition;
                        }
                        .type{
                            span{
                                color: $color-background !important;
                                @extend %transition;
                            }
                        }
                    }
                    .sub-content{
                        padding-top: 1.688rem;
                        max-height: 100%;
                        // opacity: 1;
                        // visibility: visible;
                        transition: all 0.3s ease;
                    }
                }
                @include breakpoint(XS) {
                    .cta-inner{
                        .sub-content{
                            max-height: 0;
                            margin: 0;
                        }
                    }
                }
            }
            &.hoverClass{
                .dummy-project{
                    opacity: 1;
                    visibility: visible;
                    @extend %transition;
                }
                .cta-inner{
                    // header{
                    //     h3{
                    //         @include color(color, 'background');
                    //     }
                    //     .type{
                    //         span{
                    //             @include color(color, 'background');
                    //         }
                    //     }
                    // }
                    .sub-content{
                        @include color(color, 'background');
                        .button{
                            padding: 0;
                            margin: 0;
                            justify-content: left;
                            margin-top: 1.688rem;
                        }
                    }
                }
            }
        }
        @include breakpoint(XS) {
            gap: 1rem;

            .cta-project-single{
                flex: calc(50% - 1rem) 0 0;
                aspect-ratio: 167/216;
            }
        }
    }
    .buttons{
        .button.primary{

        }
    }
}