//Configure your project's core colour palette here
// ================================================

$color-body: #092F3C;
$color-background: #FFF;

$color-accent-primary: #0E566E;
$color-accent-yellow: #ECA400;
$color-accent-magenta: #EF476F;
$color-accent-orange: #EC5500;
$color-accent-purple: #452247;
$color-accent-green: #88AB8A;
$color-accent-cream: #F5E9D3;

$color-success: #88AB8A;
$color-warning: #ECA400;
$color-error: #EC5500;

// Define your standard colour variables here
// ================================================

$color-vars: (
  body: $color-body,
  body-75: rgba($color-body, 0.75),
  body-50: rgba($color-body, 0.50),
  body-25: rgba($color-body, 0.25),
  body-10: rgba($color-body, 0.10),
  body-05: rgba($color-body, 0.05),
  body-01: rgba($color-body, 0.01),

  background: $color-background,
  background-75: rgba($color-background, 0.75),
  background-50: rgba($color-background, 0.5),
  background-25: rgba($color-background, 0.25),
  background-10: rgba($color-background, 0.10),
  background-05: rgba($color-background, 0.05),
  background-01: rgba($color-background, 0.01),

  background-alt: mix($color-body, #FFF, 95%),

  accent-primary: $color-accent-primary,
  accent-primary-lightest: mix($color-accent-primary, #FFF, 87.5%),
  accent-primary-lighter: mix($color-accent-primary, #FFF, 50%),
  accent-primary-light: mix($color-accent-primary, #FFF, 25%),
  accent-primary-dark: mix($color-accent-primary, #000, 25%),
  accent-primary-darker: mix($color-accent-primary, #000, 50%),
  accent-primary-darkest: mix($color-accent-primary, #000, 87.5%),

  accent-yellow: $color-accent-yellow,
  accent-yellow-lightest: mix($color-accent-yellow, #FFF, 87.5%),
  accent-yellow-lighter: mix($color-accent-yellow, #FFF, 50%),
  accent-yellow-light: mix($color-accent-yellow, #FFF, 25%),
  accent-yellow-dark: mix($color-accent-yellow, #000, 25%),
  accent-yellow-darker: mix($color-accent-yellow, #000, 50%),
  accent-yellow-darkest: mix($color-accent-yellow, #000, 87.5%),

  accent-magenta: $color-accent-magenta,
  accent-magenta-lightest: mix($color-accent-magenta, #FFF, 87.5%),
  accent-magenta-lighter: mix($color-accent-magenta, #FFF, 50%),
  accent-magenta-light: mix($color-accent-magenta, #FFF, 25%),
  accent-magenta-dark: mix($color-accent-magenta, #000, 25%),
  accent-magenta-darker: mix($color-accent-magenta, #000, 50%),
  accent-magenta-darkest: mix($color-accent-magenta, #000, 87.5%),

  accent-orange: $color-accent-orange,
  accent-orange-lightest: mix($color-accent-orange, #FFF, 87.5%),
  accent-orange-lighter: mix($color-accent-orange, #FFF, 50%),
  accent-orange-light: mix($color-accent-orange, #FFF, 25%),
  accent-orange-dark: mix($color-accent-orange, #000, 25%),
  accent-orange-darker: mix($color-accent-orange, #000, 50%),
  accent-orange-darkest: mix($color-accent-orange, #000, 87.5%),

  accent-purple: $color-accent-purple,
  accent-purple-lightest: mix($color-accent-purple, #FFF, 87.5%),
  accent-purple-lighter: mix($color-accent-purple, #FFF, 50%),
  accent-purple-light: mix($color-accent-purple, #FFF, 25%),
  accent-purple-dark: mix($color-accent-purple, #000, 25%),
  accent-purple-darker: mix($color-accent-purple, #000, 50%),
  accent-purple-darkest: mix($color-accent-purple, #000, 87.5%),

  accent-green: $color-accent-green,
  accent-green-lightest: mix($color-accent-green, #FFF, 87.5%),
  accent-green-lighter: mix($color-accent-green, #FFF, 50%),
  accent-green-light: mix($color-accent-green, #FFF, 25%),
  accent-green-dark: mix($color-accent-green, #000, 25%),
  accent-green-darker: mix($color-accent-green, #000, 50%),
  accent-green-darkest: mix($color-accent-green, #000, 87.5%),

  accent-cream: $color-accent-cream,
  accent-cream-lightest: mix($color-accent-cream, #FFF, 87.5%),
  accent-cream-lighter: mix($color-accent-cream, #FFF, 50%),
  accent-cream-light: mix($color-accent-cream, #FFF, 25%),
  accent-cream-dark: mix($color-accent-cream, #000, 25%),
  accent-cream-darker: mix($color-accent-cream, #000, 50%),
  accent-cream-darkest: mix($color-accent-cream, #000, 87.5%),

  accent-reverse: $color-background,

  success: $color-success,
  success-lightest: mix($color-success, #FFF, 87.5%),
  success-lighter: mix($color-success, #FFF, 50%),
  success-light: mix($color-success, #FFF, 25%),
  success-dark: mix($color-success, #000, 25%),
  success-darker: mix($color-success, #000, 50%),
  success-darkest: mix($color-success, #000, 87.5%),

  warning: $color-warning,
  warning-lightest: mix($color-warning, #FFF, 87.5%),
  warning-lighter: mix($color-warning, #FFF, 50%),
  warning-light: mix($color-warning, #FFF, 25%),
  warning-dark: mix($color-warning, #000, 25%),
  warning-darker: mix($color-warning, #000, 50%),
  warning-darkest: mix($color-warning, #000, 87.5%),

  error: $color-error,
  error-lightest: mix($color-error, #FFF, 87.5%),
  error-lighter: mix($color-error, #FFF, 50%),
  error-light: mix($color-error, #FFF, 25%),
  error-dark: mix($color-error, #000, 25%),
  error-darker: mix($color-error, #000, 50%),
  error-darkest: mix($color-error, #000, 87.5%),
);

// A mixin that gives you an appropriate fallback for browsers that don't support CSS variables (basically just IE)
// Usage example:
//  @include color(background-color, 'accent-primary', #333);
// outputs:
//  background-color: #333;
//  background-color: var(--accent-primary, #333);
//
// Tip: You don't need to include a fallback variable for most cases – you only need to use it when your desired
//      IE fallback isn't the standard default value (e.g. setting text colour over an image background)

@mixin color($property, $color, $fallback: null) {
    @if $fallback {
        #{$property}: #{$fallback};
        #{$property}: var(--color-#{$color}, #{$fallback});
    } @else {
        #{$property}: map-deep-get($color-vars, $color);
        #{$property}: var(--color-#{$color}, map-get($color-vars, $color));
    }
}