//Choose type scale values you wish to use for desktop sizes
@import '18px/type-scale-18px-1.333';

//Fudge a placeholder so we can redefine map to be desktop-specific
$desktop-placeholder: (
    placeholder: value,
);

$desktop: map-merge($type-scale, $desktop-placeholder);

//Choose type scale values you wish to use for mobile sizes
@import '16px/type-scale-16px-1.25';

//Fudge a placeholder so we can redefine map to be mobile-specific
$mobile-placeholder: (
    placeholder: value,
);

$mobile: map-merge($type-scale, $mobile-placeholder);



//Define Size mixins here
//====================================================

@mixin size-XS() {
    //fallbacks
    font-size: map-deep-get($desktop, 'sizes', 'XS');
    line-height: map-deep-get($desktop, 'line-heights', 'XS');

    font-size: clamp(#{map-deep-get($desktop, 'sizes', 'XS')}, #{map-get($desktop, 'scale-ratio')}vw, #{map-deep-get($desktop, 'sizes', 'XS')});
    line-height: clamp(#{map-deep-get($desktop, 'line-heights', 'XS')}, #{map-get($desktop, 'scale-ratio')}vw, #{map-deep-get($desktop, 'line-heights', 'XS')});
    
    //Mobile sizes
    @include breakpoint(S) {
        font-size: map-deep-get($mobile, 'sizes', 'XS');
        line-height: map-deep-get($mobile, 'line-heights', 'XS');
    }
};
  
@mixin size-S() {
    //fallbacks
    font-size: map-deep-get($desktop, 'sizes', 'S');
    line-height: map-deep-get($desktop, 'line-heights', 'S');

    font-size: clamp(#{map-deep-get($desktop, 'sizes', 'S')}, #{map-get($desktop, 'scale-ratio')}vw, #{map-deep-get($desktop, 'sizes', 'S')});
    line-height: clamp(#{map-deep-get($desktop, 'line-heights', 'S')}, #{map-get($desktop, 'scale-ratio')}vw, #{map-deep-get($desktop, 'line-heights', 'S')});
    
    //Mobile sizes
    @include breakpoint(S) {
        font-size: map-deep-get($mobile, 'sizes', 'S');
        line-height: map-deep-get($mobile, 'line-heights', 'S');
    };
};
  
@mixin size-M() {
        //fallbacks
        font-size: map-deep-get($desktop, 'sizes', 'M');
        line-height: map-deep-get($desktop, 'line-heights', 'M');
    
        font-size: clamp(#{map-deep-get($desktop, 'sizes', 'M')}, #{map-get($desktop, 'scale-ratio')}vw, #{map-deep-get($desktop, 'sizes', 'M')});
        line-height: clamp(#{map-deep-get($desktop, 'line-heights', 'M')}, #{map-get($desktop, 'scale-ratio')}vw, #{map-deep-get($desktop, 'line-heights', 'M')});
        
        //Mobile sizes
        @include breakpoint(S) {
            font-size: map-deep-get($mobile, 'sizes', 'M');
            line-height: map-deep-get($mobile, 'line-heights', 'M');
        };
};
  
@mixin size-L() {
    //fallbacks
    font-size: map-deep-get($desktop, 'sizes', 'L');
    line-height: map-deep-get($desktop, 'line-heights', 'L');

    font-size: clamp(#{map-deep-get($desktop, 'sizes', 'L')}, #{pow(map-get($desktop, 'scale-ratio'), 2)}vw, #{map-deep-get($desktop, 'sizes', 'L')});
    line-height: clamp(#{map-deep-get($desktop, 'line-heights', 'L')}, #{pow(map-get($desktop, 'scale-ratio'), 2)}vw, #{map-deep-get($desktop, 'line-heights', 'L')});
    
    //Mobile sizes
    @include breakpoint(S) {
        font-size: map-deep-get($mobile, 'sizes', 'L');
        line-height: map-deep-get($mobile, 'line-heights', 'L');
    };
};
  
@mixin size-XL() {
    //fallbacks
    font-size: map-deep-get($desktop, 'sizes', 'XL');
    line-height: map-deep-get($desktop, 'line-heights', 'XL');

    font-size: clamp(#{map-deep-get($desktop, 'sizes', 'XL')}, #{pow(map-get($desktop, 'scale-ratio'), 3)}vw, #{map-deep-get($desktop, 'sizes', 'XL')});
    line-height: clamp(#{map-deep-get($desktop, 'line-heights', 'XL')}, #{pow(map-get($desktop, 'scale-ratio'), 3)}vw, #{map-deep-get($desktop, 'line-heights', 'XL')});
    
    //Mobile sizes
    @include breakpoint(S) {
        font-size: map-deep-get($mobile, 'sizes', 'XL');
        line-height: map-deep-get($mobile, 'line-heights', 'XL');
    };
};


@mixin size-2XL() {
    //fallbacks
    font-size: map-deep-get($desktop, 'sizes', '2XL');
    line-height: map-deep-get($desktop, 'line-heights', '2XL');

    font-size: clamp(#{map-deep-get($desktop, 'sizes', '2XL')}, #{pow(map-get($desktop, 'scale-ratio'), 4)}vw, #{map-deep-get($desktop, 'sizes', '2XL')});
    line-height: clamp(#{map-deep-get($desktop, 'line-heights', '2XL')}, #{pow(map-get($desktop, 'scale-ratio'), 4)}vw, #{map-deep-get($desktop, 'line-heights', '2XL')});
    
    //Mobile sizes
    @include breakpoint(S) {
        font-size: map-deep-get($mobile, 'sizes', '2XL');
        line-height: map-deep-get($mobile, 'line-heights', '2XL');
    };
};

@mixin size-3XL() {
    //fallbacks
    font-size: map-deep-get($desktop, 'sizes', "3XL");
    line-height: map-deep-get($desktop, 'line-heights', "3XL");

    font-size: clamp(#{map-deep-get($desktop, 'sizes', "3XL")}, #{pow(map-get($desktop, 'scale-ratio'), 5)}vw, #{map-deep-get($desktop, 'sizes', "3XL")});
    line-height: clamp(#{map-deep-get($desktop, 'line-heights', "3XL")}, #{pow(map-get($desktop, 'scale-ratio'), 5)}vw, #{map-deep-get($desktop, 'line-heights', "3XL")});
    
    //Mobile sizes
    @include breakpoint(S) {
        font-size: map-deep-get($mobile, 'sizes', "3XL");
        line-height: map-deep-get($mobile, 'line-heights', "3XL");
    };
};