@import "../gutenberg_block";

.newsletter {
    width: 100%;

    input {
        flex-grow: 1;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;

        @include placeholder {
            @include color(color, 'body');
        }
    }
    button {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        position: relative;
        z-index: 2;
        margin-left: 0;
        flex-shrink: 0;
        margin-bottom: unset;
    }
}

.newsletter-footer {
    p {
        @include size-XS;
    }    
}

.graphic-content .img.graphic.newsletter-graphic {
    svg {
        height: 17.5rem;
        min-height: auto;

        @include breakpoint(S) {
            height: 14rem;
        }
    }
}

.newsletter-content {
    @include breakpoint(XS) {
        padding: 0 !important;

        .content-inner {
            text-align: left !important;
            align-items: flex-start !important;
        }
    }
}