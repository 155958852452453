@import "../gutenberg_block";

.testimonial-carousel-wrapper {
    text-align: center;

    .testimonial-carousel {
        z-index: 2;
    }
    .testimonial-carousel-slide {
        padding-top: 3rem;
        padding-bottom: 3rem;
        position: relative;

        &:before,
        &:after {
            content: "";
            position: absolute;
            width: 2px;
            height: 3rem;
            left: calc(50% - 1px);
            @include color(background-color, 'body');
        }
        &:before {
            top: 0;
        }
        &:after {
            bottom: 0;
        }
        &.lines-hide{
            padding-top: 1.375rem;
            padding-bottom: 1.375rem;
            &:before,
            &:after {
                display: none;
            }
        }
    }
    .testimonial-carousel-slide-inner {
        padding: 2rem;
        max-width: calc(100% - 50rem);
        margin: 0 auto;

        @include breakpoint(L) {
            max-width: calc(100% - 40rem);
        }
        @include breakpoint(M) {
            max-width: 38rem;
        }
        @include breakpoint(XS) {
            padding: 2rem 1.75rem;
        }
    }
}

.testimonial-carousel-slide-left-img,
.testimonial-carousel-slide-right-img {
    position: absolute;
    z-index: 5;

    img {
        width: 18rem;
        height: 23rem;
        border-radius: 0.25rem;
        position: relative;
        @extend %transition;

        @include breakpoint(L) {
            width: 14rem;
            height: 18rem;
        }
    }
    @include breakpoint(M) {
        display: none;
    }
}

.testimonial-carousel-slide-left-img {
    left: 3rem;
    top: 2.5rem;
}

.testimonial-carousel-slide-right-img {
    right: 3rem;
    bottom: -2.5rem;
}