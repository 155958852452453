li {
    @include size-M;
    margin-bottom: $unit * 2;
    padding-left: 2rem;
    position: relative;
    font-family: $font-stack-primary;
}

ul {
    margin-bottom: 1.5rem;

    &.no-margin {
        margin-bottom: 0;

        li:last-child {
            margin-bottom: 0;
        }
    }
    &.less-margin {
        margin-bottom: 0.5rem;
    }
    &.more-margin {
        margin-bottom: 2.5rem;
    }
    li {
        &:before {
            content: "";
            width: 0.5rem;
            height: 0.5rem;
            transform: rotate(45deg);
            position: absolute;
            top: 0.66rem;
            left: 0;
            @include color(background-color, 'accent-primary');
            display: block;
        }
    }
    &.unstyled {
        padding: 0;
        margin: 0;
        
        li {
            padding: 0;
            margin: 0;

            &:before {
                display: none;
            }
        }
    }
}

ol {
    margin-bottom: 1.5rem;
    counter-reset: ol-counter;

    li {
        &:before {
            position: absolute;
            left: -0.25em;
            @include color(color, 'accent-primary');
            @include size-M;
            font-weight: 700;
            font-family: $font-stack-headings;
            display: block;
            content: counter(ol-counter, decimal-leading-zero);
            counter-increment: ol-counter;
            top: 0;
            width: 1rem;
        }
    }
}