@import "../gutenberg_block";

.percentage {
    width: 100%;
    margin: 0 auto;
    background: none;
    --color-body: #{$color-accent-primary};

    &.small {
        width: calc(25% - 2rem);
        position: relative;
        margin: 1rem;

        @include breakpoint(S) {
            margin: 0.875rem;
            width: calc(50% - 1.75rem);
        }
        .donut-data {
            letter-spacing: 0.0625em;
            font-weight: bold;
            text-transform: uppercase;
        }
    }
    &.large {
        width: 100%;

        .donut-data {
            font-family: $font-stack-headings;
        }
    }
    &.default {
        --color-accent-primary: #{$color-accent-green};
    }
    &.accent {
        --color-accent-primary: #{$color-accent-primary};
    }
    &.yellow {
        --color-accent-primary: #{$color-accent-yellow};
    }
    &.magenta {
        --color-accent-primary: #{$color-accent-magenta};
    }
}

.donut-ring {
    @include color(stroke, 'body-10');
}

.donut-segment {
    transform-origin: center;
    @include color(stroke, 'accent-primary');
}

@keyframes donut {
    0% {
        stroke-dasharray: 0, 100;
    }
    100% {
        stroke-dasharray: 100, 0;
    }
}

.donut-percent {
    font-size: 0.5rem;
    transform: translateY(0.5em);
    font-family: $font-stack-headings;
    font-weight: bold;
    @include color(color, 'accent-primary');
    @include color(fill, 'accent-primary');
}

.donut-data {
    font-size: 0.1rem;
    line-height: 1;
    text-align: center;
    text-anchor: middle;
    @include color(color, 'body');
    @include color(fill, 'body');
}