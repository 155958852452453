h1,
.h1 {
    @include size-3XL;

    .subheading {
        @include size-XL;
        font-weight: 400;
    }
}

h2,
.h2 {
    @include size-2XL;

    .subheading {
        @include size-L;
        font-weight: 400;
    }
}

h3,
.h3 {
    @include size-XL;

    .subheading {
        @include size-M;
        font-weight: 400;
    }
}

h4,
.h4 {
    @include size-L;
}

h5,
.h5,
h6,
.h6 {
    @include size-M;
}

h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6 {
    font-family: $font-stack-headings;
    @include color(color, 'accent-primary');
    margin-bottom: $unit * 2;
    display: block;
    font-weight: 700;

    &.no-margin {
        margin-bottom: 0;
    }
    &.less-margin {
        margin-bottom: 0.5rem;
    }
    &.more-margin {
        margin-bottom: 2.5rem;
    }
    .subheading {
        font-weight: 400;
        margin-top: 0.5rem;
        display: block;
    }
    strong {
        text-transform: none;
        @include color(color, 'accent-primary');
    }
}

.header-line {
    display: flex;
    align-items: center;

    &:after {
        content: "";
        height: 1rem;
        flex-grow: 1;
        @include color(background-color, 'accent-primary');
        margin-left: $unit * 3;
    }
}