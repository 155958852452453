@import "../gutenberg_block";

.cta {
    &.full-width {
        width: 100%;
        margin: 0;
        min-height: 32rem;
        border-radius: 0;
        text-align: center;

        @include breakpoint(XS) {
            min-height: 22rem;
        }
        &:after {
            background: linear-gradient(90deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.25) 25.83%, rgba(0, 0, 0, 0.25) 74.17%, rgba(0, 0, 0, 0) 100%), linear-gradient(0deg, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6));
            background-blend-mode: multiply, multiply, normal;
        }
        .cta-inner {
            position: relative;
            max-width: 40rem;
            min-height: 32rem;
            padding-top: 6rem;
            padding-bottom: 6rem;

            @include breakpoint(XS) {
                padding-top: 4rem;
                padding-bottom: 4rem;
            }
            &:before,
            &:after {
                content: "";
                position: absolute;
                width: 2px;
                background: #fff;
                height: 4rem;
                left: calc(50% - 1px);

                @include breakpoint(XS) {
                    height: 2rem;
                }
            }
            &:before {
                top: 0;
            }
            &:after {
                bottom: 0;
            }
        }
    }
}
