@import "../gutenberg_block";

.cta-blocks {
    --col-gap: 2rem;

    position: relative;
    gap: var(--col-gap);

    @include breakpoint(M) {
        --col-gap: 1rem;
    }

    &.work-items {
        .cta {
            float: left;
        }
    }
}

.cta {
    --col-amount: 3;
    //Width of each item is based on parent width, minus column gaps, with the remainder divided amongst the number of columns
    --item-width: calc( ( 100% - ( ( var(--col-amount) - 1 ) * var(--col-gap) ) ) / var(--col-amount) );

    min-height: 16rem;
    border-radius: 4px;
    overflow: hidden;
    height: auto;
    width: var(--item-width);
    aspect-ratio: 1;
    position: relative;

    @include color(background-color, 'accent-primary');
    @extend %nav-transition;

    @include breakpoint(S) {
        --col-amount: 2;
    }
    @include breakpoint(XS) {
        --col-amount: 1;
    }
    &.half {
        --col-amount: 2;

        @include breakpoint(XS) {
            --col-amount: 1;
        }
    }
    &.quarter {
        --col-amount: 4;

        min-height: 0;

        .cta-inner {
            padding: 1.333rem;
        }
        @include breakpoint(S) {
            --col-amount: 2;
        }
        @include breakpoint(XS) {
            --col-amount: 1;
        }
    }
    &:after {
        @extend %nav-transition;
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 2;
        background: linear-gradient(0deg, rgba(#000, 0.2), rgba(#000, 0.2)), linear-gradient(180deg, rgba(#000, 0) 60%, #000000 100%);
        mix-blend-mode: multiply;
        border-bottom-left-radius: 4px;
        border-bottom-right-radius: 4px;
    }
    .cta-inner {
        position: absolute;
        z-index: 3;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        padding: 2rem 1.75rem;

        @include breakpoint(MS) {
            padding: 2rem 1.25rem;
        }

        @include breakpoint(XS) {
            padding: 1.5rem 1.25rem;
        }
        p:last-child {
            margin-bottom: 0;
        }
    }
    h2,
    h3,
    h4,
    h5 {
        hyphens: auto;
        width: 100%;
    }
    div {
        width: 100%;
    }
    p {
        max-width: 36rem;
        width: 100%;
        margin-left: auto;
        margin-right: auto;
    }
    img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 1;
        @extend %nav-transition;
    }
    &.hover-button {
        .button {
            opacity: 0;
            max-height: 0;
            overflow: hidden;
            padding: 0;
            @extend %nav-transition;
        }
        &:hover {
            .button {
                max-height: 4rem;
                opacity: 1;
                padding-top: 0.5rem;
            }
        }
    }
    &:hover,
    &.hover {
        &:after {
            height: 150%;
        }
    }
    &.no-img {
        border: 2px solid;

        @include color(border-color, 'accent-primary');
        @include color(color, 'body');
        @include color(background-color, 'background');

        h2,
        h3,
        h4,
        h5,
        p,
        .button {
            @include color(color, 'body');
        }
        hr {
            @include color(background-color, 'accent-primary');
        }
        &:before {
            display: none;
        }
        &:hover {
            @include color(background-color, 'accent-primary-lightest');

            .button {
                @include color(color, 'accent-primary');
            }
        }
    }
    &.not-square {
        aspect-ratio: auto;
        height: auto;

        .cta-inner {
            position: static;
        }
    }
}

.cta-grid {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    &.justify-start {
        justify-content: flex-start;
    }
    &.justify-center {
        justify-content: center;
    }
    @include breakpoint(XS) {
        margin-left: 0;
        margin-right: 0;
    }
    .cta {
        flex-grow: 0;

        @include breakpoint(M) {
            min-height: 22em;
        }
        .cta-inner {
            width: 100%;
        }
    }
}
